body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #303030;
  color: #FFFFFF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (min-width: 1320px) {
  .container {
    max-width: 1320px;
  }
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a, a:hover {
  text-decoration: none;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  *zoom: 1;
}

@font-face {
  font-family: 'FFF Forward';
  src: url(/static/media/pixel-webfont.4d11a969.eot);
  src: url(/static/media/pixel-webfont.4d11a969.eot?#iefix) format("embedded-opentype"), url(/static/media/pixel-webfont.bb8244a9.woff2) format("woff2"), url(/static/media/pixel-webfont.6b9fda52.woff) format("woff"), url(/static/media/pixel-webfont.7222af01.ttf) format("truetype"), url(/static/media/pixel-webfont.132f5ed4.svg#fff_forwardregular) format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Dogica Pixel';
  src: url(/static/media/Dogica_Pixel.186b4625.woff2) format("woff2"), url(/static/media/Dogica_Pixel.9c4dc9fd.woff) format("woff"), url(/static/media/Dogica_Pixel.5bbe4ccf.ttf) format("truetype"), url(/static/media/Dogica_Pixel.5aaf2fc4.svg#Dogica_Pixel) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: 'FFF Forward';
  background: url(/static/media/main-bg.fe609f66.png) center no-repeat;
  background-size: cover;
}

.App {
  text-align: center;
}

.header {
  padding: 15px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

@media (min-width: 992px) {
  .header .header-placeholder {
    width: 20%;
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 767px) {
  .header {
    justify-content: center;
  }
  .header .MuiButtonBase-root,
  .header .header-placeholder {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .header {
    margin-bottom: 40px;
  }
}

.header .MuiButton-containedPrimary {
  height: 50px;
}

.shiba-logo {
  background: url(/static/media/shiba-logo.16dd7d3f.png) top left no-repeat;
  background-size: contain;
  width: 230px;
  height: 80px;
}

.date-set {
  background: #1C3A29;
  color: #64FFBF;
  padding: 10px 14px;
  border-radius: 4px;
  font-size: 12px;
  display: inline-block;
  height: 20px;
  line-height: 20px;
  margin-bottom: 20px;
}

.headline-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media (min-width: 992px) {
  .headline-container {
    margin-bottom: 30px;
  }
}

.mint-container {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
}

@media (max-width: 991px) {
  .mint-box {
    margin-top: -200px;
  }
}

.mint-box {
  width: 340px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 30px 40px;
  border-radius: 4px;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  background: rgba(23, 53, 40, 0.95);
  margin-bottom: 10%;
}

.mint-box > div {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.meta-container {
  display: flex;
  justify-content: space-between;
  margin: -20px 0 20px;
}

.meta-container p.meta-info {
  font-family: 'Dogica Pixel';
  color: #64FFBF;
  margin-top: 20px;
}

.meta-container p.meta-info span {
  text-transform: uppercase;
  font-size: 10px;
  display: block;
  margin-bottom: 10px;
  color: #fff;
}

h1 {
  text-transform: uppercase;
  font-size: 11px;
  line-height: 30px;
}

@media (max-width: 991px) {
  h1 {
    padding: 0 20px;
  }
}

@media (min-width: 992px) {
  h1 {
    font-size: 24px;
    line-height: 44px;
    max-width: 50ch;
  }
}

.remaining-count {
  font-size: 12px;
  font-family: 'Dogica Pixel';
  text-align: center;
  display: block;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
}

.remaining-count > p {
  padding-top: 15px;
}

.footer-btn {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}

.btn {
  transition: .3s ease-out;
  font-family: 'Dogica Pixel';
}

.MuiButton-containedPrimary {
  font-size: 12px;
  font-family: 'Dogica Pixel' !important;
}

.MuiButton-containedPrimary:active {
  transform: scale(0.98);
}

.dark-btn {
  background: #161817;
  color: #fff;
  box-shadow: 6px 6px 0 0 rgba(24, 30, 40, 0.2);
  border-radius: 3px;
  height: 50px;
  line-height: 50px;
  padding: 2px 20px;
  font-size: 12px;
}

.dark-btn:hover {
  background: #1C7D54;
}

.MuiButton-containedPrimary,
.mint-btn {
  color: #183528 !important;
  background: #65FFBE !important;
  box-shadow: 6px 6px 0 0 rgba(24, 30, 40, 0.2);
  border-radius: 3px;
  font-family: 'FFF Forward' !important;
  text-transform: uppercase;
  font-size: 16px;
  display: flex !important;
  text-align: center;
  justify-content: center !important;
}

.MuiButton-containedPrimary:hover,
.mint-btn:hover {
  background: #fff !important;
  box-shadow: 0 0 30px -8px #5CFFBC !important;
  border-radius: 3px;
}
/*# sourceMappingURL=App.css.map */
